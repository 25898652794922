export const MUSIC_DATA = [
  {
    id: 1,
    image: "/img/music/apbtt.jpg",
    name: "ANH PHẢI BUÔNG TAY THÔI",
    artist: "THIÊN ÂN",
    url: "https://www.youtube.com/watch?v=JpUeLF8B5Uo",
    artist_url: " https://www.facebook.com/thienan.cs ",
  },
  {
    id: 2,
    image: "/img/music/ahakk.jpg",
    name: "ANH HỨA ANH KHÔNG KHÓC",
    artist: "KHÁNH TRUNG",
    url: "https://www.youtube.com/watch?v=bGzdkPzvKfU",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 3,
    image: "/img/music/vnctm.jpg",
    name: "VÌ NẮNG CŨNG THEO MƯA",
    artist: "GINA",
    url: "https://www.youtube.com/watch?v=nMkek1BF9E0",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 4,
    image: "/img/music/cdtp.jpg",
    name: "CÁNH DIỀU TÀN PHAI",
    artist: "NAM HEE",
    url: "https://www.youtube.com/watch?v=EXEwjQvNaas",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 5,
    image: "/img/music/atmc.jpg",
    name: "ÂN TÌNH MẸ CHA",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=Rc93cvb0sUY",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  {
    id: 6,
    image: "/img/music/xnccv.jpg",
    name: "XUÂN NÀY CON CHƯA VỀ",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=zulVxuN_xhA",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  {
    id: 7,
    image: "/img/music/btx.jpg",
    name: "BÁN THANH XUÂN",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=6AyUCHIGllM",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  { 
    id: 8,
    image: "/img/music/ttletl.jpg",
    name: "THU THAY LÁ EM THAY LÒNG",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=e5XkWF6FFXM",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  {
    id: 9,
    image: "/img/music/ntd.jpg",
    name: "NỬA TƠ DUYÊN",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=686Ixdf9hwg",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  {
    id: 10,
    image: "/img/music/ctth.jpg",
    name: "CON THUYỀN THA HƯƠNG",
    artist: "QUANG HẢO",
    url: "https://www.youtube.com/watch?v=JN9bwiz-7TY",
    artist_url: "https://www.facebook.com/bigzero.bigzero",
  },
  {
    id: 11,
    image: "/img/music/gvh.jpg",
    name: "GIÓ VÔ HÌNH",
    artist: "VIẾT HÒA",
    url: "https://www.youtube.com/watch?v=H3gp7bjnz_o",
    artist_url: "https://www.facebook.com/nkoc.buon.54738",
  },
  {
    id: 12,
    image: "/img/music/tlgcn.jpg",
    name: "TA LÀ GÌ CÙA NHAU",
    artist: "THIÊN ÂN, THẾ ANH",
    url: "https://www.youtube.com/watch?v=58vP2SXSpNo",
    artist_url: "https://www.facebook.com/thienan.cs",
  },
  {
    id: 13,
    image: "/img/music/nodt.jpg",
    name: "NGỰA Ô DUYÊN TÌNH",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=_LnBcXOI2Gk",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 14,
    image: "/img/music/colieu.jpg",
    name: "CÔ LIÊU",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=S042dZnqe0w",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 15,
    image: "/img/music/lnmd.jpg",
    name: "MÌNH LẠC NHAU MUÔN ĐỜI",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=MAJGfBD4fUs",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 16,
    image: "/img/music/mmkce.jpg",
    name: "MAY MẮN KHI CÓ EM",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=c5PhM-bDrkc",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 17,
    image: "/img/music/sgcdal.jpg",
    name: "SÓNG GIÓ CỨ ĐỂ ANH LO",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=4IfoEi7O_0Q",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 18,
    image: "/img/music/dpma.jpg",
    name: "ĐƠN PHƯƠNG MÌNH ANH",
    artist: "LINH TUỔI THÌN",
    url: "https://www.youtube.com/watch?v=SNts5pPOwBk",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 19,
    image: "/img/music/bnctld.jpg",
    name: "BAO NHIÊU CHÂN THÀNH LÀ ĐỦ",
    artist: "HỒ NHẬT HUY",
    url: "https://www.youtube.com/watch?v=Nv0RiJWI44Q",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 20,
    image: "/img/music/taxn.jpg",
    name: "THANH ÂM XỨ NGƯỜI",
    artist: "THÙY LINH ĐẶNG",
    url: "https://www.youtube.com/watch?v=ifm9KhbN5hk",
    artist_url: "https://www.facebook.com/profile.php?id=100004646620626",
  },

];

export const MUSIC_TOP_DATA = [
  {
    id: 1,
    image: "/img/music/nodt.jpg",
    name: "NGỰA Ô DUYÊN TÌNH",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=_LnBcXOI2Gk",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 2,
    image: "/img/music/colieu.jpg",
    name: "CÔ LIÊU",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=S042dZnqe0w",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 3,
    image: "/img/music/lnmd2.jpg",
    name: "MÌNH LẠC NHAU MUÔN ĐỜI",
    artist: "PHẠM SẮC LỆNH",
    url: "https://www.youtube.com/watch?v=MAJGfBD4fUs",
    artist_url: "https://www.facebook.com/phamsaclenh97",
  },
  {
    id: 4,
    image: "/img/music/mmkce.jpg",
    name: "MAY MẮN KHI CÓ EM",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=c5PhM-bDrkc",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 5,
    image: "/img/music/sgcdal2.jpg",
    name: "SÓNG GIÓ CỨ ĐỂ ANH LO ",
    artist: "ĐẠT VILLA",
    url: "https://www.youtube.com/watch?v=4IfoEi7O_0Q",
    artist_url: "https://www.facebook.com/datvilla94",
  },
  {
    id: 6,
    image: "/img/music/dpma2.jpg",
    name: "ĐƠN PHƯƠNG MÌNH ANH",
    artist: "LINH TUỔI THÌN",
    url: "https://www.youtube.com/watch?v=SNts5pPOwBk",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 7,
    image: "/img/music/gvh2.jpg",
    name: "GIÓ VÔ HÌNH",
    artist: "VIẾT HÒA",
    url: "https://www.youtube.com/watch?v=H3gp7bjnz_o",
    artist_url: "https://www.facebook.com/nkoc.buon.54738",
  },
  {
    id: 8,
    image: "/img/music/bnctld.jpg",
    name: "BAO NHIÊU CHÂN THÀNH LÀ ĐỦ",
    artist: "HỒ NHẬT HUY",
    url: "https://www.youtube.com/watch?v=Nv0RiJWI44Q",
    artist_url: "https://www.facebook.com/LQmedia73",
  },
  {
    id: 9,
    image: "/img/music/taxn.jpg",
    name: "THANH ÂM XỨ NGƯỜI",
    artist: "THÙY LINH ĐẶNG",
    url: "https://www.youtube.com/watch?v=ifm9KhbN5hk",
    artist_url: "https://www.facebook.com/profile.php?id=100004646620626",
  },

];
